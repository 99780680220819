import { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { CoursePreview, CourseType } from '../../types';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';
import { getCourseUrl } from '../../routes/paths';
import { Link } from 'react-router-dom';
import Message from '../translation/Message';
import InstructorItem from './InstructorItem';
import { fetchCourseTypes } from '../../store/coursesSlice';
import { courseTypesSelector } from '../../store/editCourseSlice';
import MaterialIcon from '../icons/MaterialIcon';
import CourseIcon from '../../components/icons/CourseIcon';
import { courseTheme } from '../../theme';
import noImage from '../../assets/no-image.jpg';

type CourseCardProps = {
  coursePreview: CoursePreview;
  isActive?: boolean;
  linkTo?: string;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '340px',
      height: '396px',
      borderRadius: '4px',
      boxShadow: '0px 0px 10px 0px rgba(144,144,144,.25)',
    },
    cardActionArea: {
      height: '100%',
    },
    courseName: {
      minHeight: '48px',
      fontWeight: 600,
    },
    durationBox: {
      position: 'absolute',
      bottom: 0,
      right: 0,
      background: '#4D555B',
      height: '24px',
      textAlign: 'center',
      padding: theme.spacing(0.5),
      fontWeight: 500,
      color: 'white',
      lineHeight: '17px',
    },
    subtitleBox: {
      display: 'flex',
      margin: '6px 0 10px',
      '& > * + *': {
        marginLeft: theme.spacing(1),
        alignSelf: 'center',
      },
      color: theme.palette.primary.main,
    },
    image: {
      objectFit: 'cover',
    },
    providerName: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    visibilityOffIcon: {
      position: 'absolute',
      top: '0',
      right: '0',
      padding: '1em',
    },
  })
);

const CourseCard = ({
  coursePreview,
  isActive = true,
  linkTo,
}: CourseCardProps) => {
  const classes = useStyles();
  const courseName = truncateText(coursePreview.name, 85);
  const courseTypes = useSelector(courseTypesSelector);
  const [courseType, setCourseType] = useState<CourseType | null>(null);
  const [iconName, setIconName] = useState<string>('Default');
  const dispatch = useDispatch();

  useEffect(() => {
    if (courseTypes) {
      setCourseType(
        courseTypes?.find((type) => type.id === coursePreview.courseType) ||
          null
      );
      setIconName(courseType?.icon.split('Icon')[0] || 'Default');
    }
    if (!courseTypes) {
      dispatch(fetchCourseTypes());
    }
  }, [courseTypes, coursePreview, courseType?.icon, dispatch]);

  const getTypeIconName = (): string => {
    return courseType?.icon.split('Icon')[0] || 'Default';
  };

  const getCourseTypeColor = (): string => {
    if (courseType && courseType.color) {
      return courseType.color;
    }
    return courseTheme.palette.primary.main;
  };

  const courseHasPreviewImage = coursePreview.previewImageUrl !== '';

  return (
    <Card
      variant="outlined"
      className={classes.root}
      style={{
        borderColor: courseType?.color ?? undefined,
        borderRadius: '16px',
      }}
    >
      <CardActionArea
        to={linkTo || getCourseUrl(coursePreview.slug)}
        component={Link}
        className={classes.cardActionArea}
      >
        <Box position="relative">
          {!isActive && (
            <Box className={classes.visibilityOffIcon}>
              <VisibilityOffOutlinedIcon />
            </Box>
          )}
          {!courseHasPreviewImage && (
            <CardMedia
              component="img"
              alt={coursePreview.name}
              height="192"
              width="340"
              image={noImage}
              className={classes.image}
            />
          )}
          {courseHasPreviewImage && (
            <CardMedia
              component="img"
              alt={coursePreview.name}
              height="192"
              width="340"
              image={coursePreview.previewImageUrl}
              className={classes.image}
            />
          )}
          {coursePreview.numberOfSessions > 0 && (
            <Typography variant="body2" className={classes.durationBox}>
              {coursePreview.numberOfSessions} <Message id="course.sessions" />
            </Typography>
          )}
        </Box>
        <CardContent>
          <Box
            className={classes.subtitleBox}
            style={{ color: getCourseTypeColor() }}
          >
            {iconName === 'Default' ? (
              <CourseIcon fontSize="small" />
            ) : (
              <MaterialIcon fontSize="small" iconName={getTypeIconName()} />
            )}
            <Typography variant="button" component="p" color="inherit">
              {courseType?.name || <Message id="course.course" />}
            </Typography>
          </Box>
          <Typography
            variant="subtitle2"
            component="h3"
            className={classes.courseName}
          >
            {courseName}
          </Typography>
          <Box mt={2} display="flex">
            {coursePreview.instructors.slice(0, 7).map((instructor) => (
              <Box pr={1} key={instructor.id}>
                <InstructorItem
                  instructor={instructor}
                  showName={false}
                ></InstructorItem>
              </Box>
            ))}
          </Box>
          {coursePreview.provider && (
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.providerName}
            >
              <Message id="course.by" />: {coursePreview.provider.name}
            </Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default CourseCard;

function truncateText(text: string, maxLength: number): string {
  if (text.length <= maxLength) {
    return text;
  }
  const maxLengthWithoutDots = maxLength - 3;
  const truncated = text.substr(0, maxLengthWithoutDots);
  if (text.charAt(maxLengthWithoutDots) === ' ') {
    return truncated;
  }
  return truncated.substr(0, truncated.lastIndexOf(' ')) + '...';
}
